// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconReportAnalytics} from '@tabler/icons';

// constant
const icons = {
    IconReportAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const salesAndStockStatus = {
    id: 'sales-status',
    title: <FormattedMessage id="sales-status" />,
    type: 'group',
    children: [
        {
            id: 'sales-status',
            title: <FormattedMessage id="sales-status" />,
            type: 'item',
            url: '/status/SalesAndStockStatus',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false
        }
    ]
};

export default salesAndStockStatus;
