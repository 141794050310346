import uploadFile from './upload-file';
import salesAndStockStatus from './sales-status';
import reports from './report';

// ==============================|| MENU ITEMS ||============================== //

const menuItemsDistributor = {
    items: [uploadFile, salesAndStockStatus, reports]
};

export default menuItemsDistributor;
