// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUpload, IconList } from '@tabler/icons';

// constant
const icons = {
    IconUpload,
    IconList
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const userList = {
    id: 'user-list',
    title: <FormattedMessage id="user-list" />,
    type: 'group',
    children: [
        {
            id: 'user-list',
            title: <FormattedMessage id="user-list" />,
            type: 'item',
            url: '/list/userList',
            icon: icons.IconList,
            breadcrumbs: false
        }
    ]
};

export default userList;
