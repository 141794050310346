// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import menuItemsBusinessUsers from 'menu-items-business-users';
import menuItemsDistributor from 'menu-items-distributor';


// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    console.log('Role-id', localStorage.getItem('user_token'));
    const userRoleId = localStorage.getItem('user_token')
    if (userRoleId === '1') {
        const navItems = menuItem.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    
        return <>{navItems}</>;
    }
    if (userRoleId === '2') {
        const navItems = menuItemsBusinessUsers.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    
        return <>{navItems}</>;
    }
    if (userRoleId === '3') {
        const navItems = menuItemsDistributor.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    
        return <>{navItems}</>;
    }
};

export default MenuList;
