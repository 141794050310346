// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserPlus } from '@tabler/icons';

// constant
const icons = {
    IconUserPlus
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const addNewUser = {
    id: 'add-user',
    title: <FormattedMessage id="add-user" />,
    type: 'group',
    children: [
        {
            id: 'add-user',
            title: <FormattedMessage id="add-user" />,
            type: 'item',
            url: '/addUser/addNewUser',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        }
    ]
};

export default addNewUser;
