import dashboard from './dashboard';
import userList from './user-list';
import addNewUser from './add-users';
import distributorStatus from './distributor-status';
import reports from './reports';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, userList, addNewUser, distributorStatus, reports]
};

export default menuItems;
